import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Props } from './Content.d';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: 150,
  },
}));

function Content({ children }: Props) {
  const classes = useStyles();

  return (
    <Box overflow="auto" pb={5} width="100%">
      <Container maxWidth="lg" className={classes.container}>
        {children}
        <Box pt={4}>
          <Typography variant="body2" color="textSecondary" align="center">
            Bouqs Unified Admin
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Content;
