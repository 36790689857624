import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopBar from 'components/TopBar';
import AppDrawer from 'components/AppDrawer';
import { Props } from './Layout.d';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
}));

export default function App({ children }: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <AppDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <main className={classes.main}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  );
}
