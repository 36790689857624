import { forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Props } from './ListItemLink.d';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '& *': {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  navLink: {
    paddingLeft: theme.spacing(3),
  },
}));

const renderLink = forwardRef<any, NavLinkProps>((itemProps, ref) => {
  const classes = useStyles();
  const { className, ...restProps } = itemProps;

  return (
    <NavLink
      className={clsx(classes.navLink, className)}
      activeClassName={classes.active}
      ref={ref}
      {...restProps}
    />
  );
});

function ListItemLink({ exact, children, to }: Props) {
  return (
    <li>
      <ListItem exact={exact} component={renderLink} to={to} button>
        {children}
      </ListItem>
    </li>
  );
}

export default ListItemLink;
