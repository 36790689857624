import { MouseEvent, useState } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FilterList from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Props } from './SearchBar.d';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    ...theme.mixins.toolbar,
  },
  buttonRight: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  buttonLeft: {
    marginRight: theme.spacing(2),
  },
  buttonActions: {
    marginLeft: theme.spacing(2),
  },
}));

function SearchBar({ toggleOpen }: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar className={classes.searchBar}>
      <IconButton
        onClick={toggleOpen}
        className={classes.buttonLeft}
        color="inherit"
      >
        <FilterList />
      </IconButton>
      <TextField
        size="small"
        fullWidth
        id="filled-basic"
        label="Search by Order"
        variant="outlined"
        color="primary"
      />
      <Button
        className={classes.buttonRight}
        color="primary"
        variant="contained"
        size="large"
      >
        <Search />
      </Button>
      <Button
        className={classes.buttonActions}
        color="primary"
        variant="contained"
        size="large"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Retry Inventory</MenuItem>
        <MenuItem onClick={handleClose}>Retry OMS</MenuItem>
        <MenuItem onClick={handleClose}>CX Cancel</MenuItem>
      </Menu>
    </Toolbar>
  );
}

export default SearchBar;
