/**
 * Component for demo only
 * Remove when not longer needed
 */
import { ReactNode, ChangeEvent, useMemo, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'components/Content';
import Layout from 'components/Layout';
import FiltersDrawer from 'components/FiltersDrawer';
import SearchBar from 'components/SearchBar';

import ordersData from 'orders.json';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  filterItem: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(4),
    },
    paddingTop: theme.spacing(2),
  },
}));

type SortKeys = 'asc' | 'desc';
type TableKeys =
  | 'id'
  | 'channel'
  | 'orderId'
  | 'date'
  | 'orderStatus'
  | 'inventoryStatus';

function Orders() {
  const [filterValues, setFilterValues] = useState({
    cx: false,
    ecomm: false,
    subs: false,
  });
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [orderBy, setOrderBy] = useState<SortKeys>('asc');
  const [sortByKey, setSortByKey] = useState<TableKeys>('channel');
  const classes = useStyles();

  const { cx, ecomm, subs } = filterValues;

  const data = useMemo(() => {
    return ordersData.sort((a, b) => {
      if (orderBy === 'asc') {
        return a[sortByKey].localeCompare(b[sortByKey]);
      }

      return -a[sortByKey].localeCompare(b[sortByKey]);
    });
  }, [orderBy, sortByKey]);

  const createSortClickHandler = (key: TableKeys) => () => {
    if (key !== sortByKey) {
      setOrderBy('asc');
      setSortByKey(key);
      return;
    }

    setOrderBy((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const createCheckRowHandler =
    (rowId: string) => (event: ChangeEvent<HTMLInputElement>, value: any) => {
      if (value) {
        setSelectedRows((prevSelectedRows) => [...prevSelectedRows, rowId]);
        return;
      }

      const index = selectedRows.indexOf(rowId);
      if (index > -1) {
        setSelectedRows((prevSelectedRows) => {
          prevSelectedRows.splice(index, 1);
          return [...prevSelectedRows];
        });
      }
    };

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, value: any) => {
    setFilterValues({ ...filterValues, [event.target.name]: value });
  };

  const handleOnSelectAllClick = () => {
    if (selectedRows.length > 0 || selectedRows.length === data.length) {
      setSelectedRows([]);

      return;
    }
    const allSelected = data.map((order) => order.id);

    setSelectedRows(allSelected);
  };

  const handlePageChange = (_: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <Layout>
      <SearchBar toggleOpen={toggleOpen} />
      <Box display="flex" flexDirection="row" height="100%">
        <FiltersDrawer open={open}>
          <FormControl className={classes.filterItem} component="fieldset">
            <FormLabel focused={false} component="legend">
              Channel
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={cx}
                    onChange={handleOnChange}
                    name="cx"
                  />
                }
                label="CX"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={ecomm}
                    onChange={handleOnChange}
                    name="ecomm"
                  />
                }
                label="ECOMM"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={subs}
                    onChange={handleOnChange}
                    name="subs"
                  />
                }
                label="SUBS"
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.filterItem} component="fieldset">
            <FormLabel focused={false} component="legend">
              Order Status
            </FormLabel>
            <FormGroup>
              <Autocomplete
                id="order-status"
                options={[
                  'NEW',
                  'CANCEL',
                  'OFBIZ_IMPORTED',
                  'OFBIZ_CANCELLED',
                  'OFBIZ_ERROR',
                  'ERROR',
                ]}
                getOptionLabel={(option: string) => option}
                renderInput={(params: any): ReactNode => (
                  <TextField
                    {...params}
                    placeholder="All Statuses"
                    variant="outlined"
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.filterItem} component="fieldset">
            <FormLabel focused={false} component="legend">
              Order Status
            </FormLabel>
            <FormGroup>
              <Autocomplete
                id="inventory-status"
                options={['DEDUCTED', 'INCREMENTED', 'NO_ROUTES']}
                getOptionLabel={(option: string) => option}
                renderInput={(params: any): ReactNode => (
                  <TextField
                    {...params}
                    placeholder="All Statuses"
                    variant="outlined"
                  />
                )}
              />
            </FormGroup>
          </FormControl>
        </FiltersDrawer>
        <Content>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length !== data.length
                        }
                        checked={selectedRows.length === data.length}
                        onChange={handleOnSelectAllClick}
                      />
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={sortByKey === 'channel' ? orderBy : 'asc'}
                        active={sortByKey === 'channel'}
                        onClick={createSortClickHandler('channel')}
                      >
                        Channel
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={sortByKey === 'orderId' ? orderBy : 'asc'}
                        active={sortByKey === 'orderId'}
                        onClick={createSortClickHandler('orderId')}
                      >
                        Order
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={sortByKey === 'date' ? orderBy : 'asc'}
                        active={sortByKey === 'date'}
                        onClick={createSortClickHandler('date')}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={
                          sortByKey === 'orderStatus' ? orderBy : 'asc'
                        }
                        active={sortByKey === 'orderStatus'}
                        onClick={createSortClickHandler('orderStatus')}
                      >
                        Order Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        direction={
                          sortByKey === 'inventoryStatus' ? orderBy : 'asc'
                        }
                        active={sortByKey === 'inventoryStatus'}
                        onClick={createSortClickHandler('inventoryStatus')}
                      >
                        Inventory Status
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(
                      currentPage * rowsPerPage,
                      currentPage * rowsPerPage + rowsPerPage
                    )
                    .map((order) => {
                      const isRowSelected = selectedRows.includes(order.id);

                      return (
                        <TableRow key={order.id}>
                          <TableCell>
                            <Checkbox
                              checked={isRowSelected}
                              onChange={createCheckRowHandler(order.id)}
                            />
                          </TableCell>
                          <TableCell>{order.channel}</TableCell>
                          <TableCell>{order.orderId}</TableCell>
                          <TableCell>{order.date}</TableCell>
                          <TableCell>{order.orderStatus}</TableCell>
                          <TableCell>{order.inventoryStatus}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </Content>
      </Box>
    </Layout>
  );
}

export default Orders;
