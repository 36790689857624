import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { Props } from './FiltersDrawer.d';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 275,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
}));

function FiltersDrawer({ children, open }: Props) {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <div className={classes.root}>{children}</div>
      </Slide>
    </Box>
  );
}

export default FiltersDrawer;
